import { useParams } from "react-router-dom";
import { FloatingMenu } from "../floating_menu/FloatingMenu";
import styles from "./ViewPort.module.css";

export const ViewPort = () => {
  const frameSrc = `https://view.wec360.com/demo/forsaker/oversikt`;
  const { projectName, orgName } = useParams();
  console.log(`Current Project -> ${projectName}`);
  console.log(`Current Organization -> ${orgName}`);
  return (
    <div className={styles["view-port"]}>
      <iframe
        id="iframe"
        style={{ overflow: "hidden", border: "none" }}
        title="Format Flow"
        src={frameSrc}
        scrolling="no"
        width="100%"
        height="100%"
        allowFullScreen
      ></iframe>
      <div>
        <FloatingMenu onUploadPage={false} />
      </div>
    </div>
  );
};
